import React, { Component } from 'react';
import CreateAccountWizard from './CreateAccountWizard';

export class CreateAccount extends Component {
  static displayName = CreateAccount.name;

  render() {
    return (
      <div>
        <CreateAccountWizard />
      </div>
    );
  }
}
