import React, { Fragment, useState, useEffect, Component, useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import transitions from './transitions.less';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getTimeZones, rawTimeZones, timeZonesNames, abbreviations } from "@vvo/tzdb";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loader from './Loader';
import Slider from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import googlePlayBtn from '../img/google-play-badge.png';
import appStoreBtn from '../img/AppStore.svg';
import webQR from '../img/webQR.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import parsePhoneNumber from 'libphonenumber-js'

RegExp.escape = function(s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const hasFourContinuousCharactersOverlap = (str1, str2) => {
    for (let i = 0; i <= str1.length - 4; i++) {
        const fourCharSubStr = str1.substring(i, i + 4);
        const regex = new RegExp(RegExp.escape(fourCharSubStr), "i");
        if (regex.test(str2)) {
            return true;
        }
    }
    return false;
}

const inchesToMm = (inches) => {
    return inches * 25.4;
}

const lbsToGrams = (lbs) => {
    return lbs * 453.592;
}

const validatePassword = (password, username) => {
    var lengthCheck = password.length >= 8 && password.length <= 15;
    var upperCaseCheck = /[A-Z]/.test(password);
    var lowerCaseCheck = /[a-z]/.test(password);
    var numberCheck = /[0-9]/.test(password);
    var specialCharCheck = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    var startWithLetterCheck = /^[a-zA-Z]/.test(password);
    var sameCharRepeatedMoreThan4TimesCheck = /([a-zA-Z0-9])\1{4,}/.test(password) || password ==="";
    var fourContinuousCharactersOverlapWithUsernameCheck =  username && password !=="" ? hasFourContinuousCharactersOverlap(username, password) : true;
    return (lengthCheck ? 0x1 : 0x0) |
           (upperCaseCheck ? 0x2 : 0x0) |
           (lowerCaseCheck ? 0x4 : 0x0) |
           (numberCheck ? 0x8 : 0x0) |
           (specialCharCheck ? 0x10 : 0x0) |
           (startWithLetterCheck ? 0x20 : 0x0) |
           (!sameCharRepeatedMoreThan4TimesCheck ? 0x40 : 0x0) |
           (!fourContinuousCharactersOverlapWithUsernameCheck ? 0x80 : 0x0);
}

const useSize = () => {
    const [windowSize, setWindowSize] = useState([
      window.innerHeight,
      window.innerWidth,
    ]);
  
    useEffect(() => {
      const windowSizeHandler = () => {
        setWindowSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", windowSizeHandler);
  
      return () => {
        window.removeEventListener("resize", windowSizeHandler);
      };
    }, [ ]);
  
    return windowSize;
  };

const ActivateAccountWizard = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function(event) {
        window.history.go(1);
    };

    const [state, updateState] = useState({
        form: {
            tokenStatus: 'none',
            activationStatus: 'none',
            appLinkStatus: 'none',
            agreementLinkStatus: 'none',
            userDetails: {},
            password: '',
            token: '',
            agreementStatus: 'none',
            passwordValStatus: 0x0,
            height: 0,
            weight: null,
            gender: "Select gender",
            mobileNumber: null,
            mobileNumberValid: false,
            profileInfoStatus: 'none',
            patientUuid: null
        }
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (stats) => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
        // console.log(stats);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const windowsize = useSize();

    return (
        <div>
            <StepWizard
                onStepChange={onStepChange}
                isHashEnabled={false}
                instance={setInstance}
            >
                <First form={state.form} update={updateForm} windowSize={windowsize}/>
                <Second form={state.form} update={updateForm} windowSize={windowsize}/>
                <Third form={state.form} update={updateForm} windowSize={windowsize}/>
                <Fourth form={state.form} update={updateForm} windowSize={windowsize}/>
                <Fifth form={state.form} update={updateForm} windowSize={windowsize}/>
            </StepWizard>
        </div>
    );
};

const First = props => {
    const update = (name, value) => {
        props.update(name, value);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
    if(props.form.agreementLinkStatus === 'none') {
        update('agreementLinkStatus', 'pending');
        fetch(`/cpasrv/api/get_agreement_links`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
        .then(response=>response.json())
        .then(response => {
            update('agreementLinkStatus', 'ok');
            update('agreementLinks', response.agreementLinks);
        });
    }

    if(props.form.tokenStatus === 'none') {
        const token = new URLSearchParams(window.location.search).get('token');
        update('token', token);
        update('tokenStatus', 'pending');

        fetch(`/cpasrv/api/get_activation_details/${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
        .then(response=>response.json())
        .then(response => {
            if (response.error === 'none') {
                update('tokenStatus', 'ok');
                update('userDetails', response.user);
            } else {
                if (response.error.includes("ERR06")) {
                    update('tokenStatus', 'error');
                } else if(response.error.includes("ERR07")) {
                    update('tokenStatus', 'error');
                } else if(response.error.includes("ERR05")) {
                    update('tokenStatus', 'notfound');
                }
            }
        })
        .catch(error => {
            update('tokenStatus', 'error');
        });
    } else if(props.form.tokenStatus === 'ok') {
        let nextDisabled = props.form.password && props.form.passwordValStatus === 0xff ? false : true;
        let formInputProps = {notched: false, style: {fontSize: 20, borderRadius: 12}, 
                                endAdornment: <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end">{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                                </InputAdornment>};
        let formInputLabelProps = {shrink:true, style: {fontSize: 20, top:-16, left:-14, color:"#353535", fontWeight:600}};
        let greencheck = <CheckCircleIcon sx={{color:'green'}}/>;
        let circle = <RadioButtonUncheckedIcon/>;
        return (
            <div>
                <div className="step-title-bg">
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 16 }}>Step 1 of 4</Box>
                    </Typography>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Create Account</Box>
                    </Typography>
                </div>
                <br /><br />
                <div style={{paddingLeft:8, paddingRight:8, display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <div style={{paddingLeft:8, paddingRight:8}}>
                        <Typography component="div">
                            <Box sx={{ fontWeight: 'light', fontSize: 20, color:"#5E5E5E" }}>{props.form.userDetails.firstName ? "Welcome "+props.form.userDetails.firstName+"!" : null}</Box>
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                        <TextField
                            autoFocus={true}
                            id="password-required"
                            label="CREATE YOUR PASSWORD"
                            type={showPassword ? 'text' : 'password'}
                            onChange={x => {
                                update('password', x.target.value);
                                update('passwordValStatus', validatePassword(x.target.value, props.form.userDetails.email));
                            }}
                            fullWidth
                            placeholder="Enter password"
                            InputProps={formInputProps} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                            onBlur={x => {}}
                        />
                        <br/>
                        <br/>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{props.form.passwordValStatus & 0x20 ? greencheck : circle} Starts with a letter</div>
                        </div>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{(props.form.passwordValStatus & 0x4) && (props.form.passwordValStatus & 0x2) ? greencheck : circle} At least one uppercase & lowercase letter</div>
                        </div>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{(props.form.passwordValStatus & 0x10) && (props.form.passwordValStatus & 0x8) ? greencheck : circle} At least one special character & one number</div>
                        </div>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{props.form.passwordValStatus & 0x1 ? greencheck : circle} 8 to 15 characters</div>
                        </div>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{props.form.passwordValStatus & 0x40 ? greencheck : circle} Same character not repeated more than 4x</div>
                        </div>
                        <div style={{display:'flex', fontSize:14, color:"#5E5E5E"}}>
                            <div>{props.form.passwordValStatus & 0x80 ? greencheck : circle} Four characters of email not included</div>
                        </div>
                        <br/>
                        <div style={{width: "100%"}}>
                            <button className='btn btn-primary btn-block' 
                                    disabled={nextDisabled}
                                    onClick={x=> {props.nextStep(); update('activationStatus', 'pending');}} 
                                    style={{fontSize:20, backgroundColor: nextDisabled ? "#BFBFBF": "green", border: nextDisabled ? '1px solid #9F9F9F' : '1px solid green', height:'50px', width:'100%', fontWeight:600}}>Create Password</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    } else if(props.form.tokenStatus === 'error') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>We had an issue connecting to our server.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    } else if(props.form.tokenStatus === 'notfound') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Your link has expired or is not valid.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    }    
};

const Second = props => {
    const update = (key, value) => {
        props.update(key, value);
    };

    if (props.form.activationStatus === 'pending') {
        update('activationStatus', 'inprocess');
        fetch(`/cpasrv/api/set_password/${encodeURIComponent(props.form.userDetails.email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.form) // Assuming props.form contains the data to be sent in the request body
        })
        .then(response => {
            if (response.ok) {
                update('activationStatus', 'success');
            } else {
                update('activationStatus', 'error');
            }
        })
        .catch(error => {
            update('activationStatus', 'error');
        });
    } else if(props.form.activationStatus === 'inprocess') {
        return (
            <div>
                <div className="step-title-bg">Saving Your Information</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Loader />
                    <br /><br />
                    <div style={{paddingLeft:36, paddingRight:36}}>
                        <Typography component="div">
                            <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Please wait, do not hit the back button on your browser.</Box>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    } else if(props.form.activationStatus === 'error') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>We had an issue activating your account.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Click the <b>"back"</b> button in your browser to return to the previous screen and try again.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>If the problem persists please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    } else if (props.form.activationStatus === 'success' && props.form.agreementLinkStatus === 'ok') {
        return (
            <div>
                <div className="step-title-bg">
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 16 }}>Step 2 of 4</Box>
                    </Typography>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Review Terms & Conditions</Box>
                    </Typography>
                </div>
                <br />
                <div style={{paddingLeft:8, paddingRight:8, height:(props.windowSize[0]-236)+"px", display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <iframe width="100%" height={(props.windowSize[0])+"px"} src={props.form.agreementLinks.touLink}></iframe>                    
                    <br/>
                    <div>
                        <button className='btn btn-primary btn-block' onClick={x => {props.nextStep(); update('agreementStatus','terms'); }} style={{fontSize:20, backgroundColor:"green", height: '50px', width:'100%', fontWeight:600}}>Agree</button>
                    </div>
                </div>
            </div>
        );
    }
};

const Third = props => {
    const update = (key, value) => {
        props.update(key, value);
    };

    const setAgreement = () => {
        update('agreementStatus','pending')
        fetch(`/cpasrv/api/patient_agreement/${encodeURIComponent(props.form.userDetails.email)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.form) // Assuming props.form contains the data to be sent in the request body
        })
        .then(response => response.json())
        .then(response => {
            if (response.error === 'none') {
                update('agreementStatus','success');
                update('patientUuid', response.patientUuid);
                props.nextStep();
            } else {
                update('agreementStatus','error');
            }
        })
        .catch(error => {
            update('agreementStatus','error');
        });
    }

    let nextDisabled = props.form.agreementStatus==='pending';
    if(props.form.agreementLinkStatus === 'ok') {
        return (
            <div>
                <div className="step-title-bg">
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 16 }}>Step 3 of 4</Box>
                    </Typography>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Review Privacy Policy</Box>
                    </Typography>
                </div>
                <br />
                <div style={{paddingLeft:8, paddingRight:8, height:(props.windowSize[0]-236)+"px", display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <iframe width="100%" height={props.windowSize[0]+"px"} src={props.form.agreementLinks.privacyLink}></iframe>
                    <br/>
                    <div>
                        <button disabled={nextDisabled} className='btn btn-primary btn-block' onClick={x => {setAgreement()}} style={{fontSize:20, backgroundColor: nextDisabled ? "#BFBFBF": "green", border: nextDisabled ? '1px solid #9F9F9F' : '1px solid green', height:'50px', width:'100%', fontWeight:600}}>Acknowledge and Consent</button>
                    </div>
                    {props.form.agreementStatus === 'error' ? <span>Error submitting agreement, please try again.</span> : null}
                </div>
            </div>
        );
    }
    
};

const Fourth = props => {
    const update = (key, value) => {
        props.update(key, value);
    };

    const setProfileInfo = () => {
        update('profileInfoStatus','pending');
        var profileInfo = JSON.parse(JSON.stringify(props.form)); //clone
        profileInfo["bmi"] = ((profileInfo.weight / Math.pow(profileInfo.height, 2)) * 703).toString();

        fetch(`/cpasrv/api/profile_info/${props.form.patientUuid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profileInfo) // Assuming props.form contains the data to be sent in the request body
        })
        .then(response => {
            if (response.ok) {
                update('profileInfoStatus','success');
                props.nextStep();
            } else {
                update('profileInfoStatus','error');
            }
        })
        .catch(error => {
            update('profileInfoStatus','error');
        });
    }

    let nextDisabled = (+props.form.height === 0 || 
                       props.form.weight === null || props.form.weight < 75 || 
                       props.form.gender === "Select gender" || 
                       props.form.mobileNumber === null ||
                       !props.form.mobileNumberValid) || props.form.profileInfoStatus === 'pending';
    let formInputProps = {notched: false, style: {fontSize: 20, borderRadius: 12}};
    let formInputPropsWeight = {notched: false, style: {fontSize: 20, borderRadius: 12}, endAdornment: <InputAdornment position="end">lbs</InputAdornment>};
    let formInputLabelProps = {shrink:true, style: {fontSize: 20, top:-16, left:-14}};
    let heightValues = ["Select your height"];
    for (let feet = 4; feet < 8; feet++) {
        for (let inches = 0; inches <= 11; inches++) {
            heightValues.push(`${feet}'${inches}"`);
            if(feet === 7 && inches === 6) break; //only go up to 7'6"
        }
    }
    let genderValues = ["Select gender", "Male", "Female", "Other"];

    return (
        <div>
            <div className="step-title-bg">
                <Typography component="div">
                    <Box sx={{ fontWeight: 'medium', fontSize: 16 }}>Step 4 of 4</Box>
                </Typography>
                <Typography component="div">
                    <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Confirm Personal Details</Box>
                </Typography>
            </div>
            <br /> <br />
            <div style={{paddingLeft:8, paddingRight:8}}>
                <div style={{paddingLeft:28, paddingRight:28}}>
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel shrink={true} style={formInputLabelProps.style} id="timezone-input-label">HEIGHT</InputLabel>
                        <Select
                            style={formInputProps.style}
                            value={props.form.height}
                            onChange={x => {
                                update('height', x.target.value);
                            }}
                            displayEmpty
                            >
                            {heightValues.map((x, i) => {
                                return <MenuItem style={{fontSize: 20}} key={"height"+i} value={`${i>0 ? i+47 : 0}`}>{`${x}`}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <br/><br/><br/>
                    <TextField
                            id="weight"
                            label="WEIGHT"
                            error={props.form.weight < 75 && props.form.weight !== null}
                            onChange={x => {update('weight', x.target.value.replace(/[^0-9]/g,''))}}
                            fullWidth
                            value={props.form.weight}
                            placeholder="Enter your weight"
                            InputProps={formInputPropsWeight} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                        />
                    <br/><br/><br/>
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel shrink={true} style={formInputLabelProps.style} id="gender-input-label">GENDER</InputLabel>
                        <Select
                            style={formInputProps.style}
                            value={props.form.gender}
                            onChange={x => {
                                update('gender', x.target.value);
                            }}
                            displayEmpty
                            >
                            {genderValues.map((x, i) => {
                                return <MenuItem style={{fontSize: 20}} key={"gender"+i} value={x}>{x}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <br/><br/><br/>
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel shrink={true} style={formInputLabelProps.style} id="phone-input-label">MOBILE NUMBER</InputLabel>
                        <MuiTelInput value={props.form.mobileNumber} 
                                    onChange={(x,i) => {
                                        if(x.replaceAll(" ","").length <= 12) {
                                            let pn = parsePhoneNumber(x, 'US')
                                            // let valid = matchIsValidTel(x,{onlyCountries: ['US', 'CA']});
                                            let valid = pn ? pn.isPossible() : false;
                                            update('mobileNumber', x);
                                            update('mobileNumberValid', valid);
                                        }
                                    }}
                                    defaultCountry="US" 
                                    disableDropdown 
                                    forceCallingCode
                                    getFlagElement={(isoCode, { imgProps, countryName, isSelected }) => {
                                        return <div></div>
                                      }}
                                    InputProps={formInputProps}/>
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <div>
                        <button className='btn btn-primary btn-block' 
                                disabled={nextDisabled}
                                onClick={x=> {setProfileInfo()}} 
                                style={{fontSize:20, backgroundColor: nextDisabled ? "#BFBFBF": "green", border: nextDisabled ? '1px solid #9F9F9F' : '1px solid green', height:'50px', width:'100%', fontWeight:600}}>Finish</button>
                    </div>
                    {props.form.profileInfoStatus === 'error' ? <span>Error submitting profile info, please try again.</span> : null}
                </div>
                
            </div>
        </div>
    );
};

const Fifth = props => {
    const update = (key, value) => {
        props.update(key, value);
    };
    
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if(props.form.appLinkStatus === 'none') {
        update('appLinkStatus', 'pending');
        fetch(`/cpasrv/api/get_app_links`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
        .then(response=>response.json())
        .then(response => {
            update('appLinkStatus', 'ok');
            update('appLinks', response.appLinks);
        });
    }
    else if(props.form.appLinkStatus === 'ok') {
        return (
            <div>
                <div className="step-title-bg-green">
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20, color:'white' }}>Account Created Successfully</Box>
                    </Typography>
                </div>
                <br/><br/>
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <center>
                        <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Let's install the Canary App</Box>
                        </Typography>
                    </center>
                    <br/>
                    {isAndroid ? <center>
                        <a href={props.form.appLinks.androidAppLink} target="_blank" rel="noopener noreferrer">
                            <img src={googlePlayBtn} style={{width: 200}}/>
                        </a>
                    </center> : isiOS ? <center>
                        <a href={props.form.appLinks.iosAppLink} target="_blank" rel="noopener noreferrer">
                            <img src={appStoreBtn} style={{width: 200}}/>
                        </a>
                    </center> : <center>
                        <img src={props.form.appLinks.genericAppLink} style={{width: 200}}/>
                    </center> }
                    
                    <br/><br/>
                    {isAndroid || isiOS ?
                    <Typography component="div">
                            <Box sx={{ fontWeight: 'light', fontSize: 16 }}>1. Tap the button above to open the App</Box>
                            <br/>
                            <Box sx={{ fontWeight: 'light', fontSize: 16 }}>2. Install and open the Canary Medical App</Box>
                            <br/>
                            <Box sx={{ fontWeight: 'light', fontSize: 16 }}>3. Sign-in with your Canary username (email) and password</Box>
                    </Typography> :
                    <Typography component="div">
                            <Box sx={{ fontWeight: 'light', fontSize: 16 }}>1. Download the Canary Medical App on your phone by scanning the code above or searching on your phone's app store</Box>
                            <br/>
                            <Box sx={{ fontWeight: 'light', fontSize: 16 }}>2. Open the app and sign-in with your Canary username (email) and password</Box>
                    </Typography>
                    }
                    
                </div>
            </div>
        );
    } else return(<div/>);
};

export default ActivateAccountWizard;
