import { CreateAccount } from "./components/CreateAccount";
import { ActivateAccount } from "./components/ActivateAccount";
import { Landing } from "./components/Landing";

const AppRoutes = [
  {
    index: true,
    element: <Landing />
  },
  {
    path: '/create_account',
    element: <CreateAccount />
  },
  {
    path: '/activate_account',
    element: <ActivateAccount />
  }
];

export default AppRoutes;
