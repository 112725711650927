import React, { Component } from 'react';
import CanaryLogo from '../img/Canary Bird Logo.svg';

export default class Loader extends Component {
    render() {
        let loaderStyle = {
            display: 'flex',
            position: 'absolute',
            width: '100%',
            zIndex: 999,
            justifyContent: 'center'
        }
        let loaderStyle2 = {
            display: 'flex',
            position: 'absolute',
            width: '100%',
            zIndex: 999,
            justifyContent: 'center',
            left: '-12px'
        }
        let loaderShadowboxStyle = {
            backgroundColor: '#00000040',
            opacity: '1.0',
            top: 0,
            left: 0,
            zIndex: 999,
            width: '100%',
            height: '100%',
            position: 'absolute'
        }
        let loaderContainerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }

        return <div>
            <div style={loaderContainerStyle}>
                <div style={loaderStyle}>
                    <img src={CanaryLogo} alt="Canary Logo" style={{ animation: 'fadeInOut 4s infinite', width:48 }} />
                </div>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    
  }
}