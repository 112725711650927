import React, { Fragment, useState, useEffect, Component, useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import transitions from './transitions.less';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getTimeZones, rawTimeZones, timeZonesNames, abbreviations } from "@vvo/tzdb";
import Loader from './Loader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const offsetToUTC = () => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetSign = offsetMinutes < 0 ? '+' : '-';
    return `UTC${offsetSign}${offsetHours}`;
};

const convertTZtoUTCStr = (offset) => {
    const offsetMinutes = offset;
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetSign = offsetMinutes < 0 ? '-' : '+';
    return `UTC${offsetSign}${offsetHours}`;
}

const aliasTZName = (tz) => {
    let tzname = tz;
    switch(tz) {
        case "America/New_York":
            tzname = "Eastern Time";
            break;
        case "America/Chicago":
            tzname = "Central Time";
            break;
        case "America/Denver":
            tzname = "Mountain Time";
            break;
        case "America/Los_Angeles":
            tzname = "Pacific Time";
            break;
        case "America/Anchorage":
            tzname = "Alaska Time";
            break;
        case "Pacific/Honolulu":
            tzname = "Hawaii Time";
            break;
        default:
            break;
    }
    return tzname;
}
const timeZonesWithUtc = getTimeZones({ includeUtc: true });
const sortedTimeZonesWithUtc = timeZonesWithUtc
    .filter(x => x.countryCode === "US" )
    .filter(x => x.name === "America/New_York" || 
                 x.name === "America/Chicago" || 
                 x.name === "America/Denver" || 
                 x.name === "America/Los_Angeles" || 
                 x.name === "America/Anchorage" || 
                 x.name === "Pacific/Honolulu")
    .map(x => ({ name: x.name, offset: x.rawOffsetInMinutes }))
    .sort((a, b) => a.name.localeCompare(b.offset))
    ;

const useSize = () => {
    const [windowSize, setWindowSize] = useState([
        window.innerHeight,
        window.innerWidth,
    ]);
    
    useEffect(() => {
        const windowSizeHandler = () => {
        setWindowSize([window.innerHeight, window.innerWidth]);
        };
        window.addEventListener("resize", windowSizeHandler);
    
        return () => {
        window.removeEventListener("resize", windowSizeHandler);
        };
    }, [ ]);
    
    return windowSize;
    };

const getTimeZoneOffset = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
const getTimeZoneRawOffset = (tz) => {
    try {
        return sortedTimeZonesWithUtc.filter(x => x.name === tz)[0].offset;
    } catch (error) {
        return null;
    }
}
const getTimeZoneString = (tz) => {
    try {
        return convertTZtoUTCStr(sortedTimeZonesWithUtc.filter(x => x.name === tz)[0].offset);
    } catch (error) {
        return null;
    }
}

const CreateAccountWizard = () => {

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function(event) {
        window.history.go(1);
    };

    
    const [state, updateState] = useState({
        form: {
            accountStatus: 'none', 
            timezoneOffset: getTimeZoneOffset(), 
            timeZoneRawOffset: getTimeZoneRawOffset(getTimeZoneOffset()),
            timeZoneString: getTimeZoneString(getTimeZoneOffset()),
            codeStatus: 'none', 
            formValStatus:0x3f,
            emailStatus: 'none'
        }
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (stats) => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
        // console.log(stats);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });
    const windowsize = useSize();

    return (
        <div>
            <StepWizard
                onStepChange={onStepChange}
                isHashEnabled={false}
                instance={setInstance}
            >
                <First form={state.form} update={updateForm} windowSize={windowsize}/>
                <Second form={state.form} update={updateForm} windowSize={windowsize}/>
                <Third form={state.form} update={updateForm} windowSize={windowsize}/>

            </StepWizard>
        </div>
    );
};

const First = props => {
    const update = (name, value) => {
        props.update(name, value);
    };

    const checkAccountExists = () => {
        update('emailStatus', 'pending');
        fetch(`/cpasrv/api/check_email_exists/${props.form.email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
        .then(response=>response.json())
        .then(response => {
            if (response.error === 'none') {
                update('emailStatus', 'ok');
                props.nextStep();
            } else if(response.error.includes("ERR14")){
                update('emailStatus', 'exists');
                update('formValStatus', props.form.formValStatus & ~0x4)
            } else{
                update('emailStatus', 'error');
            }
        })
        .catch(error => {
            update('emailStatus', 'error');
        });
    }

    if(props.form.codeStatus === 'none') {
        const qrCodeId = new URLSearchParams(window.location.search).get('qrcodeid');
        update('codeStatus', 'pending');

        fetch(`/cpasrv/api/get_qrcode/${qrCodeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
        .then(response=>response.json())
        .then(response => {
            if (response.error === 'none') {
                update('codeStatus', 'ok');
            } else {
                if (response.error.includes("ERR03")) {
                    update('codeStatus', 'error');
                } else if(response.error.includes("ERR02")) {
                    update('codeStatus', 'expired');
                } else if(response.error.includes("ERR01")) {
                    update('codeStatus', 'notfound');
                }
            }
        })
        .catch(error => {
            update('codeStatus', 'error');
        });
    } else if(props.form.codeStatus === 'ok') {
        let isMinor = props.form.dob && (Math.floor((new Date() - new Date(props.form.dob).getTime()) / 3.15576e+10)) < 18;
        let nextDisabled = props.form.emailStatus === 'pending' ? true : 
                          (props.form.firstname && props.form.lastname && props.form.dob && props.form.email && isEmail(props.form.email) && props.form.timezoneOffset && props.form.timeZoneRawOffset
                            && ((isMinor && props.form.guardian) || !isMinor)) ? false : true;
        let formInputProps = {notched: false, style: {fontSize: 20, borderRadius: 12}};
        let formInputLabelProps = {shrink:true, style: {fontSize: 20, top:-16, left:-14}};
        
        return (
            <div>
                <div className="step-title-bg">Enter Your Details</div>
                <br /><br />
                <div style={{paddingLeft:8, paddingRight:8, display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <div style={{paddingLeft:28, paddingRight:28}}>
                        <TextField
                            value={props.form.firstname}
                            autoFocus={true}
                            required
                            error={(props.form.formValStatus & 0x1) ? false : true}
                            id="outlined-required"
                            label="FIRST NAME"
                            onChange={x => {update('firstname', x.target.value.replace(/[^a-zA-Z]/g, ''))}}
                            fullWidth
                            placeholder="Enter First Name"
                            InputProps={formInputProps} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                            onBlur={x => {update('formValStatus', props.form.firstname ? props.form.formValStatus | 0x1 : props.form.formValStatus & ~0x1)}}
                        />
                        <br/>
                        <br/>
                        <br/>
                        <TextField
                            value={props.form.lastname}
                            required
                            error={(props.form.formValStatus & 0x2) ? false : true}
                            id="outlined-required"
                            label="LAST NAME"
                            onChange={x => {update('lastname', x.target.value.replace(/[^a-zA-Z]/g, ''))}}
                            fullWidth
                            placeholder="Enter Last Name"
                            InputProps={formInputProps} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                            onBlur={x => {update('formValStatus', props.form.lastname ? props.form.formValStatus | 0x2 : props.form.formValStatus & ~0x2)}}
                        />
                        <br/>
                        <br/>
                        <br/>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker 
                                slotProps={{
                                    textField: {
                                        required: true,
                                        InputLabelProps: formInputLabelProps, // Set font size of input label
                                        InputProps: formInputProps, // Set font size of input text
                                    },
                                }}
                                disableFuture
                                placeholder="Enter DOB DD/MM/YYYY"
                                
                                sx={{ width: "100% " }}
                                label="DATE OF BIRTH (DD/MM/YYYY)"
                                value={props.form.dob}
                                timezone="UTC"
                                onChange={x => {update('dob', x)}}
                            />
                        </LocalizationProvider>
                        <br/>
                        <br/>
                        <br/>
                        <TextField
                            required
                            error={(props.form.formValStatus & 0x4) ? false : true}
                            value={props.form.email}
                            id="outlined-required"
                            label="EMAIL ADDRESS"
                            type={"email"}
                            onChange={x => {update('email', x.target.value.replace(/[^a-zA-Z0-9.\-_@]/g,''))}}
                            fullWidth
                            placeholder="Enter Email Address"
                            InputProps={formInputProps} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                            onBlur={x => {update('formValStatus', props.form.email && isEmail(props.form.email) ? props.form.formValStatus | 0x4 : props.form.formValStatus & ~0x4)}}
                        />
                        <br/>
                        {props.form.emailStatus === 'exists' && props.form.emailStatus !== 'error' ? <span className="error-color">A Canary account for this email already exists. Check your email inbox to finish setting up your account or enter a different email to continue creating an account.</span> : null}
                        <br/>
                        <br/>
                        <br/>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel shrink={true} style={formInputLabelProps.style} id="timezone-input-label">CONFIRM TIMEZONE</InputLabel>
                            <Select
                                style={formInputProps.style}
                                value={props.form.timezoneOffset}
                                onChange={x => {
                                    let timezoneoffset = sortedTimeZonesWithUtc.filter(y => y.name === x.target.value)[0].offset;
                                    update('timezoneOffset', x.target.value);
                                    update('timeZoneRawOffset', timezoneoffset);
                                    update('timeZoneString', convertTZtoUTCStr(timezoneoffset))

                                }}
                                displayEmpty
                                >
                                {sortedTimeZonesWithUtc.map((x, i) => {
                                    return <MenuItem style={{fontSize: 20}}key={"tz"+i} value={`${x.name}`}>{`${aliasTZName(x.name)} (${convertTZtoUTCStr(x.offset)})`}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                        <br />
                        {isMinor ?
                        <div>
                        <TextField
                            value={props.form.guardian}
                            required
                            error={(props.form.formValStatus & 0x20) ? false : true}
                            id="outlined-required"
                            label="GUARDIAN NAME"
                            onChange={x => {update('guardian', x.target.value.replace(/[^\sa-zA-Z]/g, ''))}}
                            fullWidth
                            placeholder="Enter Guardian Name"
                            InputProps={formInputProps} // font size of input text
                            InputLabelProps={formInputLabelProps} // font size of input label
                            onBlur={x => {update('formValStatus', props.form.guardian ? props.form.formValStatus | 0x20 : props.form.formValStatus & ~0x20)}}
                        />
                        <br/>
                        <br/>
                        <br/>
                        </div>
                        : null}
                        <div style={{width: "100%"}}>
                            <button className='btn btn-primary btn-block' 
                                    disabled={nextDisabled}
                                    onClick={checkAccountExists} 
                                    style={{fontSize:20, fontWeight: 600, backgroundColor: nextDisabled ? "#BFBFBF": "green", border: nextDisabled ? '1px solid #9F9F9F' : '1px solid green', height:'50px', width:'100%'}}>Next</button>
                            <br/>
                            {props.form.emailStatus === 'error' && props.form.emailStatus !== 'exists' ? <span className="error-color">An error occurred. Please try again.</span> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if(props.form.codeStatus === 'error') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>We had an issue connecting to our server.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    } else if(props.form.codeStatus === 'expired') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>Expired Link</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Your invite link to create your account has expired.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Please contact our customer service team at:</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Reference Code:</Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>DID-HCPID</Box>
                    </Typography>
                </div>
            </div>
        );
    } else if(props.form.codeStatus === 'notfound') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>We did not recognize the QR Code you have scanned.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    }
};

const Second = props => {
    const update = (key, value) => {
        props.update(key, value);
    };

    let isMinor = props.form.dob && (Math.floor((new Date() - new Date(props.form.dob).getTime()) / 3.15576e+10)) < 18;

    return (
        <div>
            <div className="step-title-bg">Confirm Your Details</div>
            <br /><br />
            <div style={{paddingLeft:8, paddingRight:8, height:(props.windowSize[0]-236)+"px", display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                <div style={{paddingLeft:28, paddingRight:28}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 600, fontSize: 16 }}>FIRST NAME</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{props.form.firstname}</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 600, fontSize: 16 }}>LAST NAME</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{props.form.lastname}</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 600, fontSize: 16 }}>DATE OF BIRTH (MM/DD/YYYY)</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{props.form.dob ? props.form.dob.format('MM/DD/YYYY') : ""}</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 600, fontSize: 16 }}>EMAIL</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{props.form.email}</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 600, fontSize: 16 }}>TIMEZONE</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{aliasTZName(props.form.timezoneOffset)}</Box>
                    </Typography>
                    <br/>
                    {isMinor ?
                    <div>
                        <Typography component="div">
                            <Box sx={{ fontWeight: 600, fontSize: 16 }}>GUARDIAN</Box>
                            <Box sx={{ fontWeight: 'light', fontSize: 24, color:"#666666"}}>{props.form.guardian}</Box>
                        </Typography>
                        <br/>
                    </div> : null}
                    <button className='btn btn-primary btn-block' onClick={x => {props.previousStep(); update('accountStatus','none'); }} style={{borderColor:"#BFBFBF",fontSize:20, backgroundColor:"white", height: '50px', width:'100%', color:"black", fontWeight: 600}}>Edit</button>
                    <br />
                    <br />
                    <br />
                    <div>
                        <button className='btn btn-primary btn-block' onClick={x => {props.nextStep(); update('accountStatus','pending'); }} style={{fontSize:20, backgroundColor:"green", height: '50px', width:'100%', fontWeight: 600}}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Third = props => {
    const update = (key, value) => {
        props.update(key, value);
    };

    if (props.form.accountStatus === 'pending') {
        const qrCodeId = new URLSearchParams(window.location.search).get('qrcodeid');
        update('accountStatus', 'inprocess');
        fetch(`/cpasrv/api/create_patient/${qrCodeId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.form) // Assuming props.form contains the data to be sent in the request body
        })
        .then(response => {
            if (response.ok) {
                update('accountStatus', 'success');
            } else {
                update('accountStatus', 'error');
            }
        })
        .catch(error => {
            update('accountStatus', 'error');
        });
    }
    
    if(props.form.accountStatus === 'inprocess') {
        return (
            <div>
                <div className="step-title-bg">Saving Your Information</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Loader />
                    <br /><br />
                    <div style={{paddingLeft:36, paddingRight:36}}>
                        <Typography component="div">
                            <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Please wait, do not hit the back button on your browser.</Box>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    } else if(props.form.accountStatus === 'success') {
        return (
            <div>
                <div className="step-title-bg">Check Your Email Inbox</div>
                <br /><br />
                <div style={{ paddingLeft: 36, paddingRight: 36 }}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>We sent an email to</Box>
                        <Box sx={{ fontWeight: 'light', fontSize: 20, wordWrap: 'break-word' }}>{props.form.email}</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Go to your email to view the message.</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>Click on the green <b>"Continue Setup"</b> button in the email to finish setting up your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    } else if(props.form.accountStatus === 'exists') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>Account Already Exists</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Looks like you already have an account.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Use your email or username and password to login.</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Account Email:</Box>
                        <Box sx={{ fontWeight: 'medium', fontSize: 20}}>{props.form.email}</Box>
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div>
                        <button className='btn btn-primary btn-block' onClick={x => {props.previousStep(); update('accountStatus','none'); }} style={{backgroundColor:"white", height: '50px', width:'100%', color:"black", fontSize: 20,  fontWeight: 600}}>Cancel</button>
                    </div>
                    <br/>
                    <br/>
                    <div style={{fontSize: 20}}>
                        <center><u><b>I need assistance</b></u></center>
                    </div>
                </div>
            </div>
        );
    } else if(props.form.accountStatus === 'initialized') {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>Account Already Initiated</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Looks like you already started an account, but didn't finish. Check your email for your account activation link.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Or, tap the Resend Email button below to resend the create account email.</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>Account Email:</Box>
                        <Box sx={{ fontWeight: 'medium', fontSize: 20}}>{props.form.email}</Box>
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div>
                        <button className='btn btn-primary btn-block' onClick={x => {}} style={{backgroundColor:"green", height: '50px', width:'100%', fontSize: 20, fontWeight: 600}}>Resend Email</button>
                        <br/>
                        <br/>
                        <button className='btn btn-primary btn-block' onClick={x => {props.previousStep(); update('accountStatus','none'); }} style={{backgroundColor:"white", height: '50px', width:'100%', color:"black", fontSize: 20, fontWeight: 600}}>Cancel</button>
                    </div>
                    <br/>
                    <br/>
                    <div style={{fontSize: 20}}>
                        <center><u><b>I need assistance</b></u></center>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="step-title-err"><WarningIcon style={{paddingRight:16, fontSize:"xxx-large"}}/>An Error Occurred</div>
                <br /><br />
                <div style={{paddingLeft:36, paddingRight:36}}>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>We had an issue creating your account.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20 }}>Click the <b>"back"</b> button in your browser to return to the previous screen and try again.</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>If the problem persists please contact our Customer Service team at:</Box>
                    </Typography>
                    <br/>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 20}}>833-692-2627</Box>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20}}>to setup your account.</Box>
                    </Typography>
                </div>
            </div>
        );
    }
};

export default CreateAccountWizard;
