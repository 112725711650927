import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Container} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import canarylogo from '../img/Canary_HorLogo_Print_Pantone.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);
  }

  render() {
    return (
      <div className="container" style={{maxWidth:1200}}>
        <div className="canary-header">
          <div><img src={canarylogo} /></div>
        </div>
      </div>
    );
  }
}
