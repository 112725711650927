import React, { Component } from 'react';
import ActivateAccountWizard from './ActivateAccountWizard';

export class ActivateAccount extends Component {
  static displayName = ActivateAccount.name;

  render() {
    return (
      <div>
        <ActivateAccountWizard />
      </div>
    );
  }
}
