import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import QrScanner from 'qr-scanner';
import ReactModal from 'react-modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        padding: 0,
        overflow: 'hidden',
        background: '#000000c0',
    },
    overlay: { zIndex: 1000 }
};

export class Landing extends Component {
  static displayName = Landing.name;

constructor(props) {
    super(props);
    this.state = {
        inviteCode: '',
        showQRScanner: false,
        scanner: null
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setResult = this.setResult.bind(this);
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
}
setResult = (result) => {
    const url = new URL(result.data);
    const qrcodeid = url.searchParams.get('qrcodeid');
    
    if (qrcodeid) {
        window.location.href = '/create_account?qrcodeid='+qrcodeid;
    }
}

handleInputChange = (event) => {
    const input = event.target.value.replace(/-/g, ''); // Remove existing dashes
    const truncatedInput = input.slice(0, 32); // Truncate input at 32 characters
    const formattedInput = truncatedInput.replace(/^(.{8})?(.{4})?(.{4})?(.{4})?(.{12})?$/, (match, p1, p2, p3, p4, p5) => {
        return [p1, p2, p3, p4, p5].filter(Boolean).join('-');
    }); // Add dashes
    this.setState({ inviteCode: formattedInput });
};

handleOpenModal = () => {
    this.setState({ showQRScanner: true });
  }
  
handleCloseModal = () => {
    this.setState({ showQRScanner: false });
    this.state.scanner.stop();
}

handleAfterOpen = () => { 
    const video = document.getElementById('qr-video');
    const scanner = new QrScanner(video, result => this.setResult(result), {
        onDecodeError: error => {
            console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
    });
    this.setState({scanner: scanner});
    scanner.start();
}

render() {    
    return (
        <div>
            <div className="step-title-bg">Welcome</div>
            <br /><br />
            <div style={{ paddingLeft: 36, paddingRight: 36 }}>
                <Typography component="div">
                    <Box sx={{ fontWeight: 'medium', fontSize: 20 }}>To begin setting up your account click the button below to scan your QR code provided by your Doctor</Box>
                </Typography>
                <br/>
                <br/>
                <button className='btn btn-primary btn-block' onClick={this.handleOpenModal} style={{backgroundColor:"white", height: '50px', width:'100%', color:"black"}}>Scan QR Code</button>
            </div>
            <ReactModal
                isOpen={this.state.showQRScanner}
                contentLabel="QR Scanner"
                onAfterOpen={this.handleAfterOpen}
                style={modalStyle}
            >
                <HighlightOffIcon onClick={this.handleCloseModal} style={{position: 'relative', float:'right', cursor: 'pointer', width:32, height: 32, right: 16, top: 16, color: 'white'}} />
                <br />
                <br />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div id="video-container">
                        <video id="qr-video" width="100%" height="400" style={{objectFit: 'cover', transform:"scaleX(1)"}}></video>
                    </div>

                    <div style={{position: 'absolute', bottom:'100px', textAlign:'center'}}>
                        <Typography component="div">
                            <Box sx={{ fontWeight: 'medium', fontSize: 20, color:'white' }}>Scan the QR Code given to you by your Doctor</Box>
                        </Typography>
                    </div>
                </div>
                
            </ReactModal>
        </div>
    );
}
}